import { render, staticRenderFns } from "./MapLegend.vue?vue&type=template&id=27f85438&scoped=true&"
import script from "./MapLegend.vue?vue&type=script&lang=js&"
export * from "./MapLegend.vue?vue&type=script&lang=js&"
import style0 from "./MapLegend.vue?vue&type=style&index=0&id=27f85438&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f85438",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardTitle,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup})
