<template>
  <v-row class="app-divider">
    <v-col>
      <v-divider v-bind="$attrs" />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'AppDivider',
    inheritAttrs: false,
  }
</script>
